<template>
    <div>
        <navigation></navigation>

        <div id=container>
<!--            <div class="slider-wrap showcase om-section block-0" id=slider-9ae29c08a50641602b510d16b5084bcc>-->
<!--                <ul class="slider arrows arrows-hidden dots dots-default" data-slider-speed=5000 data-height=fixed-ratio data-att=35>-->
<!--                    <li class>-->
<!--                        <figure>-->
<!--                            <img alt="Brasserie Den Anker" src="../assets/img/DenAnker6-min.jpg">-->
<!--                            <figcaption>Brasserie Den Anker</figcaption>-->
<!--                        </figure>-->
<!--                    </li>-->
<!--                </ul>-->
<!--                <div class="overlay-wrap fixed-center">-->
<!--                    <section class=overlay>-->
<!--                        <h1>Privacyverklaring</h1>-->
<!--                        &lt;!&ndash;                                                <h2>Momenten</h2>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                                <h3>Om nooit te vergeten</h3>&ndash;&gt;-->
<!--                        &lt;!&ndash;                        <p><a class=button href="/reserveren">Reserveren</a>&ndash;&gt;-->
<!--                        &lt;!&ndash;                        </p>&ndash;&gt;-->
<!--                    </section>-->
<!--                </div>-->
<!--            </div>-->

            <div style="margin-bottom: 30px" class="content-wrap om-section block-1" id=content-9ae29c08a50641602b510d16b5084bcc><a class="anchor scrollto-anchor" id=scrollto-anchor-1></a>
                <section class=content>

                    <div id="gdprPrivacy" class="Container">
                        <header>
                            <h1>Privacyverklaring</h1>
                        </header>
                        <section>
                            <h2>Waarom deze privacyverklaring?&nbsp;</h2>

                            <p>Brasserie Den Anker hecht grote waarde aan de bescherming van uw privacy en persoonsgegevens. Wij gebruiken uw persoonsgegevens uitsluitend in overeenstemming met de Privacywet en andere relevante vigerende wettelijke
                                voorschriften.
                                Iedere verwijzing in deze Privacyverklaring naar de Privacywet betekent een verwijzing naar de Wet van 8 december 1992 tot bescherming van de persoonlijke levenssfeer ten opzichte van de verwerking van
                                persoonsgegevens. Iedere
                                verwijzing naar de Verordening is een verwijzing naar de Verordening van 27 april 2016 betreffende de bescherming van natuurlijke personen in verband met de verwerking van persoonsgegevens en betreffende het vrije
                                verkeer van die
                                gegevens.</p>

                            <p>Met deze Privacyverklaring wil Brasserie Den Anker u wijzen op eventuele verwerkingshandelingen ten aanzien van deze gegevens en op uw rechten. Door gebruik te maken van ons platform/onze website/onze applicatie verleent
                                u expliciet
                                uw toestemming met mogelijke verwerkingshandelingen door Brasserie Den Anker.</p>

                            <p>Het is mogelijk dat deze Privacyverklaring in de toekomst onderhevig is aan aanpassingen en wijzigingen. Het is aan u om op regelmatige basis dit document te raadplegen. Iedere substantiële wijziging zal steeds
                                duidelijk
                                gecommuniceerd worden op het platform van Brasserie Den Anker.</p>
                        </section>
                        <br>
                        <section>
                            <h2>Wie verwerkt de persoonsgegevens?</h2>
                            <p>Het platform/website/applicatie <em>www.brasseriedenanker.be</em> is een initiatief van:</p>
                            <p><strong>BARCODA CVOA</strong> (Hierna: “Brasserie Den Anker”)</p>
                            <address>
                                Polderstraat 1A <br>
                                2260 Westerlo (Belgium)
                            </address>

                            <p>BE 0843.524.767</p>
                            <p><a style="color:#a09174" href="mailto:kennith@brasseriedenanker.be">kennith@brasseriedenanker.be</a> <br> +32 (0)14 72 83 52</p>
                        </section>
                        <br>
                        <section>

                            <h2>Welke persoonsgegevens worden verwerkt?</h2>
                            <p>Brasserie Den Anker verbindt zich ertoe enkel de gegevens te verwerken die ter zake dienend zijn en noodzakelijk zijn voor de doeleinden waarvoor zij verzameld werden. Volgende categorieën van persoonsgegevens kunnen
                                verwerkt worden
                                door Brasserie Den Anker.</p>
                            <ul>
                                <li>Identificatiegegevens</li>
                                <li>Financiële bijzonderheden</li>
                                <li>Persoonlijke kenmerken</li>
                                <li>Fysieke gegevens</li>
                                <li>Leefgewoonten</li>
                                <li>Samenstelling van het gezin</li>
                                <li>Vrijetijdsbesteding en interessen</li>
                                <li>Lidmaatschappen</li>
                                <li>Consumptiegewoonten</li>
                                <li>Woningkenmerken</li>
                                <li>Gegevens omtrent opleiding en vorming</li>
                                <li>Gegevens omtrent beroep en betrekking</li>
                                <li>Beeldopnamen</li>
                            </ul>
                        </section>
                        <br>
                        <section>

                            <h2>Voor welke doeleinden worden mijn persoonsgegevens gebruikt?</h2>

                            <p>Brasserie Den Anker verzamelt persoonsgegevens om u een veilige, optimale en persoonlijke gebruikerservaring te bieden. De verzameling van persoonsgegevens wordt uitgebreider naarmate u intensiever gebruik maakt van het
                                platform/de
                                website/de applicatie en onze online dienstverlening.</p>

                            <p>Gegevensverwerking is essentieel voor de werking van het platform/de website/de applicatie en de daarbij horende diensten. De verwerking gebeurt uitsluitend voor volgende welbepaalde doeleinden:</p>

                            <ul>
                                <li>De detectie van en bescherming tegen fraude, fouten en/of criminele gedragingen.</li>
                                <li>Het aanbieden en verbeteren van een algemene en gepersonaliseerde dienstverlening; inclusief facturatiedoeleinden, aanbod van informatie, nieuwsbrieven en aanbiedingen die nuttig en/of noodzakelijk zijn voor u, de
                                    verkrijging
                                    en verwerking van gebruikersbeoordelingen en het verlenen van ondersteuning.
                                </li>
                                <li>U toegang verschaffen tot uw gebruikersprofiel.</li>
                                <li>Het aanbieden en verbeteren van de aangeleverde producten; persoonsgerichte en specifieke producten aan de hand van geleverde informatie en gegevens.</li>
                                <li>Marketing doeleinden</li>
                            </ul>

                            <p>Bij een bezoek aan het platform/de website/de applicatie van Brasserie Den Anker worden er enkele gegevens ingezameld voor statistische doeleinden. Dergelijke gegevens zijn noodzakelijk om het gebruik van ons
                                platform/onze
                                website/onze applicatie te optimaliseren. Deze gegevens zijn: IP-adres, vermoedelijke plaats van consultatie, uur en dag van consultatie, welke pagina’s er werden bezocht. Wanneer u het platform/de website/de
                                applicatie van
                                Brasserie Den Anker bezoekt, verklaart u zich akkoord met deze gegevensinzameling bestemd voor statische doeleinden zoals hierboven omschreven.</p>

                            <p>De Gebruiker verschaft steeds zelf de persoonsgegevens aan Brasserie Den Anker en kan op die manier een zekere controle uitoefenen. Indien bepaalde gegevens onvolledig of ogenschijnlijk incorrect zijn, behoudt Brasserie
                                De Post zich
                                het recht voor bepaalde verwachte handelingen tijdelijk of permanent uit te stellen.</p>

                            <p>De persoonsgegevens worden enkel verwerkt voor intern gebruik binnen Brasserie Den Anker.</p>

                            <p>We kunnen u dan ook geruststellen dat persoonsgegevens niet verkocht, doorgegeven of meegedeeld worden aan derde partijen die aan ons verbonden zijn. Brasserie Den Anker heeft alle mogelijke juridische en technische
                                voorzorgen
                                genomen om ongeoorloofde toegang en gebruik te vermijden.</p>

                        </section>
<!--                        <br>-->
<!--                        <section>-->

<!--                            <h2>Wij gebruiken ook cookies!</h2>-->

<!--                            <p>Tijdens een bezoek aan ons platform/onze website kunnen 'cookies' op uw harde schijf geplaatst worden om het platform/de website beter af te stemmen op de behoeften van de terugkerende bezoeker. Niet-functionele cookies-->
<!--                                helpen ons-->
<!--                                om uw bezoek aan het platform te optimaliseren en om technische keuzes te herinneren.</p>-->

<!--                            <p>Voor een verder begrip van de wijze waarop wij cookies gebruiken om uw persoonsgegevens te verzamelen en te verwerken, verwijzen wij u graag door naar onze <a href="/nl-BE/cookieverklaring/">Cookieverklaring</a>.</p>-->

<!--                            <p>Als u het platform/de website van Brasserie Den Anker wil consulteren, is het aan te raden dat u cookies ingeschakeld hebt. Hoe u cookies daarentegen kan uitschakelen, staat eveneens te lezen in onze-->
<!--                                <a href="/nl-BE/cookieverklaring/">Cookieverklaring</a>.</p>-->

<!--                        </section>-->
                        <br>
                        <section>

                            <h2>Wat zijn mijn rechten?</h2>

                            <h3>Garantie van een rechtmatige en veilige verwerking van de persoonsgegevens</h3>

                            <p>Brasserie Den Anker verwerkt uw persoonsgegevens steeds eerlijk en rechtmatig. Dit houdt volgende garanties in:</p>

                            <ul>
                                <li>Persoonsgegevens worden enkel conform de omschreven en gerechtvaardigde doeleinden van deze Privacyverklaring verwerkt.</li>
                                <li>Persoonsgegevens worden enkel verwerkt voor zover dit toereikend, ter zake dienend en niet overmatig is.</li>
                                <li>Persoonsgegevens worden maar bewaard zolang dit noodzakelijk is voor het verwezelijken van de omschreven en gerechtvaardigde doeleinden in deze Privacyverklaring.</li>
                            </ul>

                            <p>De nodige technische en beveiligingsmaatregelen werden genomen om de risico’s op onrechtmatige toegang tot of verwerking van de persoonsgegevens tot een minimum te reduceren. Bij inbraak in haar informaticasystemen zal
                                Brasserie De
                                Post onmiddellijk
                                alle mogelijke maatregelen nemen om de schade tot een minimum te beperken.</p>

                            <h3>Recht op inzage/rectificatie/wissen van uw persoonsgegevens</h3>

                            <p>Bij bewijs van uw identiteit als Gebruiker, beschikt u over een recht om van Brasserie Den Anker uitsluitsel te krijgen over het al dan niet verwerken van uw persoonsgegevens. Wanneer Brasserie Den Anker uw gegevens
                                verwerkt, heeft u
                                bovendien het recht om inzage
                                te verkrijgen in de verzamelde persoonsgegevens. Indien u wenst uw recht op inzage te gebruiken, zal Brasserie Den Anker hieraan gevolg geven binnen één (1) maand na het ontvangen van de aanvraag. De aanvraag gebeurt via
                                aangetekende zending
                                of via het versturen van een e-mail naar <a style="color:#a09174" href="mailto:kennith@brasseriedenanker.be">kennith@brasseriedenanker.be</a></p>

                            <p>Onnauwkeurige of onvolledige persoonsgegevens kunnen steeds verbeterd worden. Het is aan de Gebruiker om in de eerste plaats zelf onnauwkeurigheden en onvolledigheden aan te passen. U kan uw recht op verbetering
                                uitoefenen door een
                                aanvullende verklaring
                                te verstrekken aan Brasserie Den Anker. Brasserie Den Anker zal hieraan gevolg geven binnen één (1) maand na het ontvangen van de aanvullende verklaring.</p>

                            <p>U heeft bovendien het recht om zonder onredelijke vertraging uw persoonsgegevens door ons te laten wissen. U kan slechts beroep doen op dit recht om vergeten te worden in de hiernavolgende gevallen:</p>

                            <ul>
                                <li>Wanneer de persoonsgegevens niet langer nodig zijn voor de doeleinden waarvoor zij oorspronkelijk werden verzameld;</li>
                                <li>Wanneer de persoonsgegevens verzameld werden op basis van verkregen toestemming en geen andere rechtsgrond bestaat voor de verwerking;</li>
                                <li>Wanneer bezwaar wordt gemaakt tegen de verwerking en geen prevalerende dwingende gerechtvaardigde gronden voor de verwerking bestaan;</li>
                                <li>Wanneer de persoonsgegevens onrechtmatig werden verwerkt;</li>
                                <li>Wanneer de persoonsgegevens gewist moeten worden overeenkomstig een wettelijke verplichting.</li>
                            </ul>

                            <p>Brasserie Den Anker beoordeelt de aanwezigheid van een van de voornoemde gevallen.</p>

                            <h3>Recht op beperking van/bezwaar tegen de verwerking van uw persoonsgegevens</h3>

                            <p>Gebruiker heeft het recht om een beperking van de verwerking van uw persoonsgegevens te verkrijgen:</p>

                            <ul>
                                <li>Gedurende de periode die Brasserie Den Anker nodig heeft om de juistheid van de persoonsgegevens te controleren, in geval van betwisting;</li>
                                <li>Wanneer de gegevensverwerking onrechtmatig is en Gebruiker verzoekt tot een beperking van de verwerking in plaats van het wissen van de persoonsgegevens;</li>
                                <li>Wanneer Brasserie Den Anker de persoonsgegevens van de Gebruiker niet meer nodig heeft voor de verwerkingsdoeleinden en Gebruiker de persoonsgegevens nodig heeft inzake een rechtsvordering;</li>
                                <li>Gedurende de periode die Brasserie Den Anker nodig heeft om de aanwezigheid van de gronden voor het wissen van persoonsgegevens te beoordelen.</li>
                            </ul>

                            <p>Gebruiker heeft bovendien te allen tijde het recht om bezwaar te maken tegen de verwerking van zijn persoonsgegevens. Brasserie Den Anker staakt hierna de verwerking van uw persoonsgegevens, tenzij Brasserie Den Anker
                                dwingende
                                gerechtvaardigde gronden voor de verwerking
                                van uw persoonsgegevens kan aanvoeren die zwaarder wegen dan het recht van de Gebruiker op bezwaar.</p>

                            <p>Indien Gebruiker wenst om deze rechten uit te oefenen, zal Brasserie Den Anker hieraan gevolg geven binnen één (1) maand na het ontvangen van de aanvraag. De aanvraag gebeurt via aangetekende zending of via een e-mail
                                naar
                                <a style="color:#a09174" href="mailto:kennith@brasseriedenanker.be">kennith@brasseriedenanker.be</a></p>

                            <h3>Recht op gegevensoverdraagbaarheid</h3>

                            <p>Gebruiker heeft het recht om de aan Brasserie Den Anker verstrekte persoonsgegevens in een gestructureerde, gangbare en machine leesbare vorm te verkrijgen. Daarnaast heeft Gebruiker het recht om deze persoonsgegevens
                                over te dragen
                                aan een andere verwerkingsverantwoordelijke
                                wanneer de verwerking van de persoonsgegevens uitsluitend rust op de verkregen toestemming van de Gebruiker.</p>

                            <p>Indien Gebruiker wenst om dit recht uit te oefenen, zal Brasserie Den Anker hieraan gevolg geven binnen één (1) maand na het ontvangen van de aanvraag. De aanvraag gebeurt via aangetekende zending of via een e-mail naar
                                <a style="color:#a09174" href="mailto:kennith@brasseriedenanker.be">kennith@brasseriedenanker.be</a>. </p>

                            <h3>Recht op het intrekken van mijn toestemming/recht om klacht in te dienen</h3>

                            <p>Gebruiker heeft te allen tijde het recht om zijn toestemming in te trekken. Het intrekken van de toestemming laat de rechtmatigheid van de verwerking op basis van de toestemming vóór de intrekking daarvan, onverlet.
                                Daarnaast heeft
                                Gebruiker het recht om klacht in te dienen betreffende de verwerking van zijn persoonsgegevens door Brasserie Den Anker bij de Belgische Commissie voor de Bescherming van de Persoonlijke Levenssfeer.</p>

                            <p>Indien Gebruiker wenst dit recht uit te oefenen, zal Brasserie Den Anker hieraan gevolg geven binnen één (1) maand na het ontvangen van de aanvraag. De aanvraag gebeurt via aangetekende zending of via e-mail naar
                                <a style="color:#a09174" href="mailto:kennith@brasseriedenanker.be">kennith@brasseriedenanker.be</a>.</p>

                        </section>
                    </div>
                </section>
            </div>
        </div>

        <app-footer></app-footer>

        <svg id=icon-sprite-social>
            <defs>
                <symbol id=icon-facebook>
                    <path d="M3.7 10h2.1V5h1.4l.2-1.7H5.8v-.9c0-.4 0-.7.7-.7h.9V0H5.9C4.3 0 3.7.8 3.7 2.2v1h-1V5h1v5z"/>
                </symbol>
            </defs>
        </svg>

    </div>

</template>

<script>
    import AppFooter  from '../components/Footer'
    import Navigation from '../components/Navigation'

    export default {
        name: 'PrivacyStatement',
        components: {
            Navigation, AppFooter,
        },
        mounted() {
            $(window).scrollTop(100)
        }
    }
</script>

<style scoped>
h2{
    margin-bottom: 20px
}
    h3 {
        margin: 10px 0 15px 0
    }
</style>
